import type { ReactNode } from 'react';

import type { LogFunction, LogLevel } from '@/utils/logger/types';

export const getPath = () => '/api/log';

export type ContextProps = Record<LogLevel, LogFunction>;

export type ProviderProps = {
  children?: ReactNode;
};
