import type { FC } from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { createContext } from 'react';

import type { CyberError } from './error';
import type {
  ContextProps,
  ErrorAt,
  ErrorState,
  ErrorType,
  FrontendErrorType,
  ProviderProps,
  ServerErrorType,
} from './types';

const defaultContext: ContextProps = {
  error: undefined,
  setGlobalError: () => {},
};

const ErrorContext = createContext<ContextProps>(defaultContext);

export const ErrorContextProvider: FC<ProviderProps> = ({ children }) => {
  const [error, setError] = useState<ErrorState>();
  const setGlobalError = (at: ErrorAt, type: ErrorType, err: CyberError) => {
    if (at === 'server') {
      setError({ at, type: type as ServerErrorType, err });
      return;
    }
    setError({ at, type: type as FrontendErrorType, err });
  };
  const value = {
    error,
    setGlobalError,
  };
  return <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>;
};

export const useError = () => useContext(ErrorContext);
