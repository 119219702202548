import type { AppProps } from 'next/app';
import type { Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import { appWithTranslation } from 'next-i18next';

import { ErrorContextProvider } from '@/context/error';
import { LoggerContextProvider } from '@/context/logger';

const App = ({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps<{
  session: Session;
}>) => {
  return (
    <LoggerContextProvider>
      <ErrorContextProvider>
        <SessionProvider session={session}>
          <Component {...pageProps} />
        </SessionProvider>
      </ErrorContextProvider>
    </LoggerContextProvider>
  );
};

export default appWithTranslation(App);
