import type { FC } from 'react';
import { useContext } from 'react';
import { useMemo } from 'react';
import { createContext } from 'react';

import type { User } from '@sentry/nextjs';
import axios from 'axios';

import type { LogFunction, LogLevel, LogOptionals, LogType } from '@/utils/logger/types';

import type { ContextProps, ProviderProps } from './types';
import { getPath } from './types';

const defaultContext: ContextProps = {
  debug: () => {},
  warn: () => {},
  info: () => {},
  error: () => {},
};

const LoggerContext = createContext<ContextProps>(defaultContext);

const createLogger =
  (level: LogLevel, user?: User) =>
  (type: LogType, msg: string, optionals: LogOptionals = {}) =>
    axios.post(getPath(), {
      level,
      type,
      msg,
      optionals: {
        ...optionals,
        user,
      },
    });

export const LoggerContextProvider: FC<ProviderProps> = ({ children }) => {
  // TODO: auth 周りを実装したら id, email, name など必要なものを取り出して user に詰め込む
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const user = {};
  const debug: LogFunction = useMemo(() => createLogger('debug', user), [user]);
  const info: LogFunction = useMemo(() => createLogger('info', user), [user]);
  const warn: LogFunction = useMemo(() => createLogger('warn', user), [user]);
  const error: LogFunction = useMemo(() => createLogger('error', user), [user]);
  const value = {
    debug,
    info,
    warn,
    error,
  };
  return <LoggerContext.Provider value={value}>{children}</LoggerContext.Provider>;
};

export const useLogger = () => useContext(LoggerContext);
